import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Router from 'next/router';
import getConfig from "next/config";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form, FieldGroup, Button, AlphanumericInput, Toggle, Heading, Select, SelectOption } from '@lmig/lmds-react';
import WrapperSizeable from "../Utility/WrapperSizeable";

const {
  publicRuntimeConfig: {
    regex: {
      address: addressRegex,
      name: nameRegex,
    },
  },
} = getConfig();

const FindAgentSearchBar = ({
  isLocationSearch = true,
  radius = 25,
  searchVal
}) => {
  const [curVal, setCurVal] = useState(undefined);
  const [curRadius, setCurRadius] = useState(radius);
  const [isLocation, setIsLocation] = useState(true);
  const radiusList = [
    { value: '5', label: '5 miles or less' },
    { value: '10', label: '10 miles' },
    { value: '15', label: '15 miles' },
    { value: '20', label: '20 miles' },
    { value: '25', label: '25 miles (default)' },
    { value: '50', label: '50 miles' },
    { value: '75', label: '75 miles' },
    { value: '100', label: '100 miles' },
    { value: '150', label: '150 miles' },
    { value: '200', label: '200 miles' },
    { value: '250', label: '250 miles' },
  ];

  const validationSchema = isLocation ?
    yup.object().shape({
      query: yup
        .string()
        .required('Search is a required field')
        .matches(addressRegex, 'Must enter an address with valid characters'),
    })
    : yup.object().shape({
      query: yup
        .string()
        .required('Name is a required field')
        .matches(nameRegex, 'Must enter a name with valid characters'),
    });

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    setCurRadius(radius);
  }, [radius]);

  useEffect(() => {
    setIsLocation(isLocationSearch);
  }, [isLocationSearch]);

  useEffect(() => {
    setCurVal(searchVal);
    setValue('query', searchVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchVal]);

  const submitSearch = () => {
    let passedQuery = {};

    if (isLocation) {
      passedQuery = { query: curVal, radius: curRadius, location: true };
    } else {
      passedQuery = { query: curVal, location: false };
    }

    if (curRadius !== radius || searchVal !== curVal) {
      const params = new URLSearchParams(window?.location?.search);
      const passedQueryParams = new URLSearchParams(passedQuery);

      passedQueryParams.forEach((value, name) => {
        params.set(name, value);
      });
      Router.push(
        {
          pathname: '/insurance-agent',
          passedQuery,
        },
        `/insurance-agent?${params.toString()}`,
      );
    }
  };

  const onAlphaChange = (curSearch) => {
    setValue('query', curSearch);
    setCurVal(curSearch);
  };

  const radiusChange = (e) => {
    setCurRadius(e.target.value);
  };

  const toggleSwitch = () => {
    setIsLocation(!isLocation);
    setCurVal("");
    setCurRadius(25);
  };

  const validationMessages = Object.entries(errors).map(([, { message }]) => ({ type: 'error', text: message }));

  return (
    <WrapperSizeable backgroundColor="lightLibertyTeal" margin={0} padding="1rem" id="findAgentSearch">
      <Heading type='h2-bold' className="searchHeading" tag="h1">Find an agent</Heading>
      <Toggle className="toggle" activeLabel="Search by location" inactiveLabel="Search by location" checked={isLocation} labelVisual="Search by name" onChange={() => toggleSwitch()} data-testid="location-toggle" id="location-toggle" />
      <Form id="find-an-agent-search" data-testid="search-find-an-agent-form" onSubmit={handleSubmit(submitSearch)}>
        <FieldGroup messages={validationMessages} className="formFieldGroup">
          <div className="formContent">
            <div data-theme="evolved" className="searchBar">
              <AlphanumericInput
                labelVisual={isLocation ? "Search by ZIP Code, city, address" : "Search by agent name"}
                onChange={(e) => onAlphaChange(e.target.value)}
                type="search"
                value={curVal}
                data-testid="find-agent-search-bar"
                id="find-agent-search-bar"
              />
            </div>
            {isLocation &&
              <div data-theme="evolved" className="radiusSelect">
                <Select labelVisual="Search radius" value={curRadius} onChange={(e) => radiusChange(e)} data-testid="radius-drop-down">
                  {radiusList.map(({ value, label }) => <SelectOption key={value} value={value.toString()} label={label}>{label}</SelectOption>)}
                </Select>
              </div>}
            <div data-theme="evolved" className="searchButtonWrapper">
              <Button id="find-an-agent-search-button" type="submit" data-testid="search-agent-submit" variant="primary" className="searchButton">Search</Button>
            </div>
          </div>
        </FieldGroup>
      </Form>
      <style global jsx>{`@import './FindAgentSearchBar.scoped.scss';`}</style>
    </WrapperSizeable>
  );
};

FindAgentSearchBar.propTypes = {
  isLocationSearch: PropTypes.bool,
  radius: PropTypes.number,
  searchVal: PropTypes.string,
};

export default FindAgentSearchBar;